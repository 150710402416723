import React from "react"
import Layout from '../components/Layout'
import { Link } from "gatsby"

import TelegramSvg from '../assets/svg/telegram.svg'
import TwitterSvg from '../assets/svg/twitter.svg'
import DiscordSvg from '../assets/svg/discord.svg'
import GithubSvg from '../assets/svg/github.svg'
import MediumSvg from '../assets/svg/medium.svg'
import YoutubeSvg from '../assets/svg/youtube.svg'
import StackoverflowSvg from '../assets/svg/stackoverflow.svg'
import EmailSvg from '../assets/svg/mail.svg'

import placeHolderImg from '../assets/images/girl-in-glasses.jpg'
import andrewImg from '../assets/images/andrew-headshot.jpeg'
import twitterImg from '../assets/images/twitter.jpeg'

import './community.scss'

import { pageLink, googleConversion, conversionId } from '../utils'


// data
const socialMedias = [
  { icon: <TelegramSvg />, channel: "Telegram", hrefLink: pageLink.telegram, googleId: conversionId.telegram },
  { icon: <TwitterSvg />, channel: "Twitter", hrefLink: pageLink.twitter, googleId: conversionId.twitter},
  { icon: <DiscordSvg />, channel: "Discord", hrefLink: pageLink.discord, googleId: conversionId.discord },
  { icon: <GithubSvg />, channel: "Github", hrefLink: pageLink.github, googleId: conversionId.github },
  { icon: <MediumSvg />, channel: "Medium", hrefLink: pageLink.medium, googleId: conversionId.medium },
  { icon: <YoutubeSvg />, channel: "Youtube", hrefLink: pageLink.youtube, googleId: conversionId.youtube },
  { icon: <StackoverflowSvg />, channel: "Stack Overflow", hrefLink: pageLink.stackOverflow, googleId: conversionId.stackOverflow },
  { icon: <EmailSvg />, channel: "Email", hrefLink: pageLink.email, googleId: conversionId.email },
]

const news = [
  { 
    image: andrewImg, 
    link: pageLink.tedx, 
    text: "Our founder and CEO, Andrew Kwan will be sharing his vision on the importance of an open and connected Metaverse at the forthcoming TEDxChater Road."
  },
  { 
    image: twitterImg, 
    link: pageLink.twitter, 
    text: "We regularly share demos, exclusive news and updates about our token launch on Twitter. Connect with us there for early access and special offers."}
]

// Components
const SocialBox = ({icon, text, hrefLink, googleId}) => (
  <a className="box" href={hrefLink} target="_blank" rel="noreferrer" aria-label={text} onClick={() => googleConversion(googleId)}>
    { icon }
    <div className="box__text">{ text }</div>
  </a>
)

const NewsBox = ({ photo, text, link}) => (
  <div className="box" key={ text.split(' ')[0] } >
    <img src={ photo } alt="" />
    <p className="box__caption">{ text }</p>
    <a className="box__button button button-100" href={ link } target="_blank" rel="noreferrer" onClick={() => googleConversion(conversionId.readArticle)}>Read Article</a>
  </div>
)

const community = () => (
  <Layout
    title="Double | Community"
    pageClassName="page-community"
  >
    {/* HERO */}
    <section className="page-community__section-hero">
      <div className="page-community__section-hero__container">
        <h1 className="page-title">Double Community</h1>
        <p className="page-title-tag">Our community channels are a hub for developers, token holders, sellers and supporters to stay connected and informed.</p>
      </div>
    </section>

    <div className="page-community__section-connect-news-wrapper">
      {/* CONNECT */}

      <section className="page-community__section-connect">
        <div className="page-community__section-connect__container">
          <h2 className="title">Connect with Double</h2>
          <p className="title-tag">We post to these channels regularly and will continue to diversify our community building efforts as we grow. </p>
          <div className="social-boxes">
            { socialMedias.map(media => <SocialBox key={ media.channel } icon= { media.icon } text={ media.channel } hrefLink={media.hrefLink} googleId={media.googleId}/>) }
          </div>
        </div>
      </section>

      {/* NEWS */}
      <section className="page-community__section-news">
        <div className="page-community__section-news__container">
          <h2 className="title">News</h2>
          <p className="title-tag">Hear from our CEO, industry partners and broader network. You’ll also find all of the latest media coverage of Double.</p>
          <div className="news-boxes">
            { news.map((singleNews, index) => <NewsBox photo={ singleNews.image } text={ singleNews.text } link={ singleNews.link } key={index} googleId={ singleNews.googleId } />) }
          </div>
        </div>
      </section>
    </div>

  </Layout>
)

export default community;
